export const idDocumentOptions = [
  {
    id: 1,
    code: "00",
    value: "D.N.I.",
    abbreviature: "DNI",
    maxlength: 8,
    minlength: 8, 
  },
  {
    id: 2,
    code: "01",
    value: "Carnet extranjería",
    abbreviature: "CE",
    maxlength: 13,
    minlength: 4, 
  },
  {
    id: 3,
    code: "04",
    value: "Pasaporte",
    abbreviature: "PAS",
    maxlength: 12,
    minlength: 4, 
  },
  {
    id: 4,
    code: "06",
    value: "Carnet del Permiso Temporal de Permanencia",
    abbreviature: "PTP",
    maxlength: 9,
    minlength: 4,
  }
];
