import React, { useEffect, useState } from 'react';
import { StepsCircle } from '@prima-afp/prima-ui-elements/dist/core';
import { MainContainer } from '../../components/MainContainer';
import { Navbar } from '../../components/Navbar';
import { Steps } from '../styles';
import { OTPAuthenticator } from '@prima-afp/prima-security/module';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { usePostChangeForeign } from 'modules/biometrics-data-update-new/hooks/usePostChangeForeign';
import { useQueryClient } from 'react-query';
import DataUpdateWebServiceFetcher from 'modules/shared/libs/ServiceFetcher';
import { ErrorModal } from 'modules/shared/components/Error';

const initialState = [
  {
    id: 1,
    title: 'Datos de contacto',
    current: true,
    active: false,
  },
  {
    id: 2,
    title: 'Validación de identidad',
    current: false,
    active: false,
  },
];

export const StepOtpVerification = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const history = useHistory();
  const authToken = queryClient.getQueryData('authToken');
  const { mutate, modalSettings } = usePostChangeForeign();
  const [isErrorModalActive, setIsErrorModalActive] = useState(false);

  const otpValue = JSON.parse(localStorage.getItem('otpVerifycate'));
  const validateDocument = JSON.parse(localStorage.getItem('validateDocument'));
  const storageHeader = JSON.parse(localStorage.getItem('authToken'));

  const { userWebDataDto } = validateDocument || {};
  const state = location?.state || {};

  const paths = {
    sendEmailCode: `${process.env.REACT_APP_API_URL_DATA_UPDATE_WEB}/update-data-affiliated/biometric/mail/generation`,
    validateEmailCode: `${process.env.REACT_APP_API_URL_DATA_UPDATE_WEB}/update-data-affiliated/biometric/mail/verification`,
    sendSMSCode: `${process.env.REACT_APP_API_URL_DATA_UPDATE_WEB}/update-data-affiliated/biometric/sms/generation`,
    validateSMSCode: `${process.env.REACT_APP_API_URL_DATA_UPDATE_WEB}/update-data-affiliated/biometric/sms/verification`,
  };

  const affiliate = {
    documentType: state?.biometricState?.documentType,
    documentNumber: state?.biometricState?.documentNumber,
    phoneNumber: otpValue?.phoneNumber,
    idBiometric: state?.requestIdValidation,
    generationMailRequest: {
      email: otpValue?.email,
      firstName: otpValue?.name,
    },
  };

  const handleSubmit = async () => {
    if (userWebDataDto?.documentType !== '00') {
      await mutate({
        body: {
          documentType: userWebDataDto?.documentType,
          documentNumber: userWebDataDto?.documentNumber,
          email: otpValue?.email,
          idBiometric: state?.requestIdValidation,
          validationType: '1',
        },
        token: storageHeader,
      });
    }
  };
  const documentType = validateDocument?.documentType;

  const handleFinish = () => {
    history.push('/actualizacion-datos-biometria/finished');
  };

  const onAccess = (value) => {
    if (value === 'success') {
      handleFinish();
    }
    if (value === 'failed') {
      window?.location?.replace('');
    }
  };

  
useEffect(() => {
  if (userWebDataDto && userWebDataDto.documentType && userWebDataDto.documentType !== '00') {
    handleSubmit();
  }
}, [userWebDataDto?.documentType]);


  return (
    <>
    {documentType === '00' && (
        <MainContainer>
          <Navbar back={true} />
          <div className='tw-mb-2'>
            <Steps>
              <StepsCircle center steps={initialState} />
            </Steps>
          </div>
          <OTPAuthenticator
            affiliate={affiliate}
            serviceFetcher={DataUpdateWebServiceFetcher}
            paths={paths}
            onAccess={(value) => onAccess(value)}
            headers={authToken ? { ...authToken } : { ...storageHeader }}
            layoutType={'EMBED'}
            isPublic={true}
          />
        </MainContainer>
      )}
      <ErrorModal
        closeModal={() => setIsErrorModalActive(false)}
        settings={{
          ...modalSettings,
          isActive: isErrorModalActive,
          closeModal: () => setIsErrorModalActive(false),
        }}
      />
    </>
  );
};
