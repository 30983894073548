import { useMutation, useQueryClient } from 'react-query';
import { getHeaderQAuth } from '../services';

export const useGetTokenQauth = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ keys, headers }) => getHeaderQAuth(keys, headers),
    {
      onSuccess: (data) => {
        const token = data?.access_token;
        queryClient.setQueryData('authToken', { Authorization: `Bearer ${token}` });
        localStorage.setItem('authToken', JSON.stringify({ Authorization: `Bearer ${token}` }));
      },
      onSettled: () => {
        console.log("fetchToken completado");
      },
      onError: (error) => {
        console.error("Error en fetchToken:", error);
      },
    }
  );
};
