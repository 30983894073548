import React, { useState, useEffect } from "react";
import {
  Input,
  Option,
  RadioButton,
  Select,
  StepsCircle,
  Typography,
} from "@prima-afp/prima-ui-elements/dist/core";
import { StepTitle } from "../../../shared/components/StepTitle";
import { FormContainer } from "../styles";
import { Steps } from "../styles";
import { MainContainer } from "../../components/MainContainer";
import { StyledButton } from "../../components/Button/styles";
import { Container } from "@prima-afp/prima-ui-elements/dist/layouts";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";
import { GTMEvents } from "../../../shared/utils/GTMevents";
import { useGetTokenQauth } from "../../hooks/useGetTokenQauth";

const initialState = [
  {
    id: 1,
    title: "Datos de contacto",
    current: true,
    active: false,
  },
  {
    id: 2,
    title: "Validación de identidad",
    current: false,
    active: false,
  },
];

export default function StepContactPersonal({ setCurrentStep }) {
  const history = useHistory();
  const queryClient = useQueryClient();
  const data =
    queryClient.getQueryData("validateDocument") ||
    JSON.parse(localStorage.getItem("validateDocument"));
  const { documentType, documentNumber, name, lastName, email, phoneNumber } =
    data || {};

  const [identificationDocumentType] = useState(documentType);
  const [identificationDocumentNumber] = useState(documentNumber);
  const [patchEmail, setPatchEmail] = useState(""); // Email actualizado
  const [patchEmailConfirm, setPatchEmailConfirm] = useState(""); // Confirmación del email
  const [steps] = useState(initialState);
  const [focusEmail, setFocusEmail] = useState(true);
  const [focusEmailConfirm, setFocusEmailConfirm] = useState(true);
  const [focusPhoneConfirm, setFocusPhoneConfirm] = useState(true);
  const [isUpdateDataContact, setIsUpdateDataContact] = useState({
    firstCheck: false,
    secondCheck: false,
  });
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailConfirmError, setEmailConfirmError] = useState("");
  const [disabledButton, setDisabledButton] = useState(true);
  const [inputValue, setInputValue] = useState(""); // Phone number actualizado
  const [isFormFilled, setIsFormFilled] = useState(false);

  const { mutate: fetchToken } = useGetTokenQauth({
    onSuccess: (data) => {
      const token = data?.access_token;
      if (token) {
        console.log("Token obtenido:", token);
        localStorage.setItem("accessToken", token);
        console.log(
          "Nuevo token almacenado:",
          localStorage.getItem("accessToken")
        );
      } else {
        console.error("No se pudo obtener un token válido.");
      }
    },
    onError: (error) => {
      console.error("Error al obtener el token:", error);
    },
  });

  const handleGenerateToken = () => {
    console.log("Generando token...");
    const TOKEN_CLIENT_SECRET = process.env.REACT_APP_TOKEN_CLIENT_SECRET;
    const TOKEN_CLIENT_ID = process.env.REACT_APP_TOKEN_CLIENT_ID;
    const TOKEN_SCOPE = process.env.REACT_APP_TOKEN_SCOPE;

    const bodyRequest = `client_secret=${encodeURIComponent(
      TOKEN_CLIENT_SECRET
    )}&client_id=${encodeURIComponent(
      TOKEN_CLIENT_ID
    )}&scope=${encodeURIComponent(TOKEN_SCOPE)}`;

    return new Promise((resolve, reject) => {
      fetchToken(
        {
          keys: bodyRequest,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Ocp-Apim-Subscription-Key":
              process.env.REACT_APP_SUBSCRIPTION_KEY_OAUTH,
          },
        },
        {
          onSuccess: (data) => {
            console.log("fetchToken completado");
            const token = data?.access_token;
            if (token) {
              localStorage.setItem("accessToken", token);
              console.log("Token actualizado en handleGenerateToken:", localStorage.getItem("accessToken"));
              resolve(token);
            } else {
              reject(new Error("No se pudo obtener un token válido."));
            }
          },
          onError: (error) => {
            console.error("Error al generar el token:", error);
            reject(error);
          },
        }
      );
    });
  };

  const handleNextStep = async () => {
    try {
      await handleGenerateToken();
      // Almacenar los valores en localStorage antes de continuar con el siguiente paso
      localStorage.setItem(
        "otpVerifycate",
        JSON.stringify({
          phoneNumber: inputValue || phoneNumber,
          email: patchEmail || email,
          emailConfirm: patchEmailConfirm,
          name: name,
          documentType: identificationDocumentType,
          documentNumber: identificationDocumentNumber,
        })
      );

      localStorage.setItem(
        "userData",
        JSON.stringify({
          patchEmail: patchEmail,
        })
      );

      history.push({
        pathname: "/validacion-biometrica/validacion-inicial/62",
        state: {
          initialRedirect: true,
          document_type: identificationDocumentType,
          document_number: identificationDocumentNumber,
        },
      });
    } catch (error) {
      console.error("Error generando un nuevo token:", error);
    }
  };

  const handleFinish = () => {
    history.push({
      pathname: "/actualizacion-datos-biometria",
      state: {
        initialRedirect: true,
        document_type: identificationDocumentType,
        document_number: identificationDocumentNumber,
      },
    });
  };

  useEffect(() => {
    const isEmailValid =
      isValidEmail(patchEmail) && isValidEmail(patchEmailConfirm);
    const isPhoneValid =
      documentType === "00" ? isValidPhone(inputValue) : true;
    const isRequiredFieldsFilled =
      patchEmail && patchEmailConfirm && (inputValue || documentType !== "00");
    const isDisabled =
      !isEmailValid || !isPhoneValid || !isRequiredFieldsFilled;
    setDisabledButton(isDisabled);
    setIsFormFilled(isEmailValid && isPhoneValid && isRequiredFieldsFilled);
  }, [patchEmail, patchEmailConfirm, inputValue, documentType]);

  const handleBlur = () => {
    if (!patchEmail.trim()) {
      setEmailError("Dato Obligatorio");
      GTMEvents.ErrorFormInputDataUserToChange(
        "Dato Obligatorio  - Correo Electrónico"
      );
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(patchEmail)) {
      setEmailError("Formato de correo electrónico inválido");
      GTMEvents.ErrorFormInputDataUserToChange(
        "Formato de correo electrónico inválido"
      );
    } else {
      setEmailError("");
    }
  };

  const handleBlurConfirm = () => {
    if (!patchEmailConfirm.trim()) {
      setEmailConfirmError("Dato Obligatorio");
      GTMEvents.ErrorFormInputDataUserToChange(
        "Dato Obligatorio - Correo Electrónico a Confirmar"
      );
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(patchEmail)) {
      setEmailConfirmError("Formato de correo electrónico inválido");
      GTMEvents.ErrorFormInputDataUserToChange(
        "Formato de correo electrónico a confirmar inválido"
      );
    } else if (patchEmail && patchEmailConfirm !== patchEmail) {
      setEmailConfirmError("El correo electrónico no coincide");
      GTMEvents.ErrorFormInputDataUserToChange(
        "El correo electrónico no coincide"
      );
    } else {
      setEmailConfirmError("");
    }
  };

  const handleBlurPhone = () => {
    if (!inputValue.trim()) {
      setPhoneError("Dato Obligatorio");
      GTMEvents.ErrorFormInputDataUserToChange("Dato Obligatorio - Celular");
    } else if (inputValue.length !== 9 || !inputValue.startsWith("9")) {
      setPhoneError("Número de celular inválido");
      GTMEvents.ErrorFormInputDataUserToChange("Número de celular inválido");
    } else {
      setPhoneError("");
    }
  };

  const isValidPhone = (phoneNumber) => {
    if (!phoneNumber.trim()) {
      return false;
    }
    if (phoneNumber.length !== 9 || !phoneNumber.startsWith("9")) {
      return false;
    }
    return true;
  };

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  return (
    <>
      <MainContainer>
        <Steps>
          <StepsCircle center steps={steps} />
        </Steps>

        <StepTitle className="tw-mb-1.5 tw-mt-2">
          Verifica tus datos personales
        </StepTitle>

        {/* Formulario de actualización de datos */}
        <FormContainer className="tipoDocumento">
          <Select
            className="tipoDocumento-select"
            label={""}
            value={identificationDocumentType}
            disabled
          >
            <Option value={"00"} key={"00"}>
              {"DNI"}
            </Option>
            <Option value={"01"} key={"01"}>
              {"CE"}
            </Option>
            <Option value={"04"} key={"04"}>
              {"PAS"}
            </Option>
            <Option value={"06"} key={"06"}>
              {"PTP"}
            </Option>
          </Select>
          <Input
            className="tipoDocumento-input"
            type="text"
            value={identificationDocumentNumber}
            disabled
          />
        </FormContainer>

        <div className="md:tw-flex tw-justify-between tw-mb-1">
          <div
            style={{ width: "100%", "margin-right": "1%" }}
            className="tw-mb-1 md:tw-mb-0"
          >
            <Input
              type="text"
              label="Nombres"
              className="md:tw-mr-1"
              value={name}
              disabled
            />
          </div>
          <Input
            type="text"
            label="Apellidos"
            className="md:tw-ml-1"
            value={lastName}
            disabled
          />
        </div>

        <div className="md:tw-flex tw-justify-between tw-mb-3">
          <div
            style={{
              width: documentType !== "00" ? "50%" : "100%",
              marginRight: "1%",
            }}
            className="tw-mb-1 md:tw-mb-0"
          >
            <Input
              type="text"
              label="Correo"
              className="md:tw-mr-1"
              value={email || ""}
              disabled
            />
          </div>
          {documentType === "00" && (
            <Input
              type="text"
              label="Celular"
              className="md:tw-ml-1"
              value={phoneNumber || ""}
              disabled
            />
          )}
        </div>

        <div className="tw-justify-between tw-mb-1">
          <Typography className="tw-mb-2">
            ¿Deseas actualizar los datos de contacto que tenemos registrados?
          </Typography>

          <div className="tw-flex tw-justify-between tw-mb-1">
            <div className="tw-flex tw-justify-start tw-w-1/2">
              <RadioButton
                className="tw-mb-1.5"
                label="Si"
                value="y"
                checked={isUpdateDataContact?.firstCheck}
                onClick={() => {
                  GTMEvents.BtnOptionsStepOne("si");
                  setIsUpdateDataContact({
                    firstCheck: true,
                    secondCheck: false,
                  });
                  setDisabledButton(false);
                }}
              ></RadioButton>
            </div>
            <div className="tw-flex tw-justify-start tw-w-1/2">
              <RadioButton
                label="No"
                value="n"
                checked={isUpdateDataContact?.secondCheck}
                onClick={() => {
                  GTMEvents.BtnOptionsStepOne("no");
                  setIsUpdateDataContact({
                    firstCheck: false,
                    secondCheck: true,
                  });
                  setDisabledButton(false);
                }}
              ></RadioButton>
            </div>
          </div>

          {isUpdateDataContact?.firstCheck && (
            <>
              <Typography className="tw-mb-1">
                Ingresa los datos de tus nuevos medios de contacto:
              </Typography>

              <div className="md:tw-flex tw-justify-between tw-mb-1 md:tw-md-2">
                <div
                  className="md:tw-w-1/2"
                  style={{ position: "relative", "margin-right": "1%" }}
                >
                  <Input
                    type="text"
                    label={
                      patchEmail?.length > 0
                        ? false
                        : "Ingresa tu nuevo correo electrónico"
                    }
                    className="md:tw-mr-1 tw-mb-1 md:tw-mb-0"
                    onChange={(event) => {
                      const emailValue = event.target.value;
                      setPatchEmail(emailValue);
                    }}
                    onFocus={() => setFocusEmail(true)}
                    onBlur={() => {
                      setFocusEmail(false);
                      handleBlur();
                    }}
                    style={{ marginRight: "3%" }}
                  />
                  {emailError && (
                    <p style={{ margin: "0", fontSize: "14px", color: "red" }}>
                      {emailError}
                    </p>
                  )}
                </div>
                <div
                  style={{ position: "relative" }}
                  className="md:tw-w-1/2 tw-mt-1 md:tw-mt-0"
                >
                  <Input
                    type="text"
                    label={
                      patchEmailConfirm?.length > 0
                        ? false
                        : "Confirma tu correo electrónico"
                    }
                    className="md:tw-ml-1"
                    onChange={(event) => {
                      const emailValue = event.target.value;
                      setPatchEmailConfirm(emailValue);
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onFocus={() => setFocusEmailConfirm(true)}
                    onBlur={() => {
                      setFocusEmailConfirm(false);
                      handleBlurConfirm();
                    }}
                  />
                  {emailConfirmError && (
                    <p style={{ margin: "0", fontSize: "14px", color: "red" }}>
                      {emailConfirmError}
                    </p>
                  )}
                </div>
              </div>

              {documentType === "00" && (
                <div className="md:tw-flex tw-justify-between tw-mb-3 md:tw-w-1/2">
                  <div style={{ position: "relative", width: "100%" }}>
                    <Input
                      name="numberPhone-input"
                      type="text"
                      label={
                        inputValue.length > 0
                          ? false
                          : "Ingresa tu número de celular"
                      }
                      maxLength={9}
                      onChange={(event) => {
                        const value = event.target.value;
                        setInputValue(value);
                      }}
                      onFocus={() => setFocusPhoneConfirm(true)}
                      onBlur={() => {
                        setFocusPhoneConfirm(false);
                        handleBlurPhone();
                      }}
                    />
                    {phoneError && (
                      <p
                        style={{ margin: "0", fontSize: "14px", color: "red" }}
                      >
                        {phoneError}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </MainContainer>

      <Container className="sm:tw-flex tw-justify-end">
        <>
          {documentType === "00" && ( // CON DNI
            <StyledButton
              onClick={
                isUpdateDataContact.firstCheck ? handleNextStep : handleFinish
              }
              disabled={
                disabledButton ||
                (!isFormFilled && !isUpdateDataContact.secondCheck)
              }
            >
              {isUpdateDataContact.firstCheck ? "Siguiente" : "Finalizar"}
            </StyledButton>
          )}
          {documentType !== "00" && ( // CON CE / PASAPORTE
            <StyledButton
              onClick={
                isUpdateDataContact.firstCheck ? handleNextStep : handleFinish
              }
              disabled={
                disabledButton ||
                (!isFormFilled && !isUpdateDataContact.secondCheck)
              }
            >
              {isUpdateDataContact.firstCheck ? "Siguiente" : "Finalizar"}
            </StyledButton>
          )}
        </>
      </Container>
    </>
  );
}
