import { useMutation, useQueryClient } from 'react-query';
import { useState } from 'react';
import { obtenerDatos } from '../services';
import { useHistory } from 'react-router-dom';

const postValidateDocument = async ({ body, token }) => {
  if (!token) {
    throw new Error("No token available");
  }

  const subscriptionKey = process.env.REACT_APP_SUBSCRIPTION_KEY_VALIDATE;

  if (!subscriptionKey) {
    throw new Error("Subscription key no encontrada. Asegúrate de que la variable de entorno esté configurada.");
  }

  const res = await obtenerDatos(body, {
    Authorization: token,
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": subscriptionKey,
  });

  let data;

  if (typeof res.json === 'function') {
    data = await res.json();
  } else {
    data = res;
  }
  
  return data;
};

const redirectToForm = () => {
  window.location.href = '/actualizacion-datos-biometria/form';
};

export const useDataDocument = () => {
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isErrorModalActive, setIsErrorModalActive] = useState(false);

  const { mutate, isLoading, error, data, isSuccess, isError } = useMutation(
    postValidateDocument,
    {
      onError: (error) => {
        setErrorMessage(
          error.message === 'Affiliate not found.'
            ? 'No hemos podido identificarte como nuestro afiliado. Inténtalo nuevamente.'
            : 'Lo sentimos, estamos trabajando para solucionar el inconveniente.'
        );
        setIsErrorModalActive(true);
      },
      onSuccess: (data) => {
        setErrorMessage(null);      
        if (data?.message) {
          setErrorMessage(
            data.message === 'Affiliate not found.'
              ? 'No hemos podido identificarte como nuestro afiliado. Inténtalo nuevamente.'
              : 'Lo sentimos, estamos trabajando para solucionar el inconveniente.'
          );
          setIsErrorModalActive(true);
          return;
        }
      
        const { names, surnames, documentType, documentNumber, phoneNumber, email } = data || {};
      
        if (!names || !surnames || !documentType || !documentNumber) {
          setErrorMessage('Lo sentimos, estamos trabajando para solucionar el inconveniente.');
          setIsErrorModalActive(true);
          return;
        }
      
        const splitName = names.trimEnd().split(' ');
        const name = splitName.length > 1 ? `${splitName[0]} ${splitName[1]}` : splitName[0];
      
        const userData = {
          documentNumber: documentNumber,
          documentType: documentType,
          name: name,
          lastName: surnames.trim(),
          email: email,
          phoneNumber: phoneNumber,
        };
      
        queryClient.setQueryData('validateDocument', userData);
        localStorage.setItem('validateDocument', JSON.stringify(userData));
      
        redirectToForm();
      },      
    }
  );

  const handleCloseModal = () => {
    setIsErrorModalActive(false);
  };

  return {
    mutate,
    isLoading,
    isError,
    error,
    errorMessage,
    data,
    isSuccess,
    handleCloseModal,
    modalSettings: {
      type: 'alert',
      isActive: isErrorModalActive,
      title: 'Error',
      content: errorMessage,
      closeModal: handleCloseModal,
    },
  };
};
