import { useMutation, useQueryClient } from 'react-query';
import { useState } from 'react';
import { saveDataForeign } from '../services';
import { useHistory } from 'react-router-dom';

const postValidateForeign = async ({ body, token }) => {
  if (!token) {
    throw new Error('Token no disponible');
  }
  const res = await saveDataForeign(body, token);
  if (!res.ok) {
    throw new Error(`Error en la solicitud: ${res.statusText}`);
  }
  const data = await res.json();
  return data;
};

export const usePostChangeForeign = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isErrorModalActive, setIsErrorModalActive] = useState(false);

  const postValidateForeign = async ({ body, token }) => {
    if (!token) {
      throw new Error("No hay token disponible");
    }

    const validateDocument = queryClient.getQueryData('validateDocument') ||
      JSON.parse(localStorage.getItem("validateDocument"));

    if (!validateDocument) {
      throw new Error("No se encontró validateDocument");
    }

    const { documentNumber, documentType, email } = validateDocument;

    const requestBody = {
      documentNumber,
      documentType,
      email,
      idBiometric: body.idBiometric, // idBiometric se pasa en body
      validationType: "1"
    };

    const res = await saveDataForeign(requestBody, token);

    if (!res.ok) {
      throw new Error("Error en la respuesta del servidor");
    }

    return await res.json();
  };

  const { mutate, isLoading, error, data, isSuccess } = useMutation(
    postValidateForeign,
    {
      onError: (error) => {
        setIsErrorModalActive(true);
        setErrorMessage(error.message);
      },
      onSuccess: () => {
        setErrorMessage(null);
        history.push("/actualizacion-datos-biometria/finished");
      },
    }
  );

  const handleCloseModal = () => {
    setIsErrorModalActive(false);
  };

  return {
    mutate,
    isLoading,
    error,
    errorMessage,
    data,
    isSuccess,
    handleCloseModal,
    modalSettings: {
      type: "alert",
      isActive: isErrorModalActive,
      title: "Error",
      content:
        "Lo sentimos, estamos trabajando para solucionar el inconveniente.",
      closeModal: handleCloseModal,
    },
  };
};
